var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "밀폐공간작업 상세정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "출입사유",
                    name: "closeEntryResaon",
                  },
                  on: { dataChange: _vm.dataChange },
                  model: {
                    value: _vm.supWork.closeEntryResaon,
                    callback: function ($$v) {
                      _vm.$set(_vm.supWork, "closeEntryResaon", $$v)
                    },
                    expression: "supWork.closeEntryResaon",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "밀폐장소의 예상위험",
                    name: "closeExpectedRisk",
                  },
                  on: { dataChange: _vm.dataChange },
                  model: {
                    value: _vm.supWork.closeExpectedRisk,
                    callback: function ($$v) {
                      _vm.$set(_vm.supWork, "closeExpectedRisk", $$v)
                    },
                    expression: "supWork.closeExpectedRisk",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-tag", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    itemText: "closeEntryUserName",
                    itemValue: "check",
                    icon: "face",
                    label: "출입자",
                    name: "entrants",
                  },
                  on: { removeTag: _vm.removeEntrant, addTag: _vm.addEentrant },
                  model: {
                    value: _vm.supWork.entrants,
                    callback: function ($$v) {
                      _vm.$set(_vm.supWork, "entrants", $$v)
                    },
                    expression: "supWork.entrants",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5" },
          [
            _c("c-table", {
              staticClass: "q-mt-md",
              attrs: {
                title: "안전조치 요구사항",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.supWork.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7" },
          [
            _c(
              "c-table",
              {
                ref: "gasTable",
                staticClass: "q-mt-md",
                attrs: {
                  title: "가스농도 측정",
                  columns: _vm.grid2.columns,
                  gridHeight: _vm.grid2.height,
                  data: _vm.supWork.gases,
                  plantCd: _vm.workPermit.plantCd,
                  vendorCd: _vm.workPermit.vendorCd,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  editable: _vm.editable && _vm.isWriting,
                  selection: "multiple",
                  rowKey: "sopGasCheckId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.isWriting
                          ? _c("c-btn", {
                              attrs: {
                                label: "추가",
                                icon: "add",
                                showLoading: false,
                              },
                              on: { btnClicked: _vm.addGas },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.isWriting
                          ? _c("c-btn", {
                              attrs: {
                                label: "제외",
                                icon: "remove",
                                showLoading: false,
                              },
                              on: { btnClicked: _vm.removeGas },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm.editable && _vm.isWriting
        ? _c(
            "div",
            {
              staticClass:
                "alert alert-info alert-dismissible fade show cursor-pointer",
              staticStyle: {
                "margin-bottom": "13px !important",
                padding: "10px 3px !important",
              },
              attrs: { role: "alert" },
              on: { click: _vm.setPermitData },
            },
            [
              _c(
                "span",
                { staticClass: "q-pl-sm q-pr-md" },
                [
                  _c("q-icon", {
                    attrs: { name: "o_emoji_objects", size: "sm" },
                  }),
                ],
                1
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 발급 ")]
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 협조자 ")]
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 안전조치확인 ")]
              ),
              _vm._v(" 정보를 허가서에서 가져오기 "),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "발급 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "issuedDeptCd",
                          type: "dept_user",
                          label: "발급",
                          beforeText: "",
                          name: "userId1",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.issuedUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "issuedUserId", $$v)
                          },
                          expression: "supWork.issuedUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "approvalDeptCd",
                          type: "dept_user",
                          label: "승인",
                          beforeText: "",
                          name: "userId2",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.approvalUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "approvalUserId", $$v)
                          },
                          expression: "supWork.approvalUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "협조자 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "relationCooperation1DeptCd",
                          type: "dept_user",
                          label: "협조자-1",
                          beforeText: "",
                          name: "relationCooperation1UserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.relationCooperation1UserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "relationCooperation1UserId",
                              $$v
                            )
                          },
                          expression: "supWork.relationCooperation1UserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "relationCooperation2DeptCd",
                          type: "dept_user",
                          label: "협조자-2",
                          beforeText: "",
                          name: "relationCooperation2UserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.relationCooperation2UserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "relationCooperation2UserId",
                              $$v
                            )
                          },
                          expression: "supWork.relationCooperation2UserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "안전조치확인 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "maintenanceDeptCheckDeptCd",
                          type: "dept_user",
                          label: "책임자",
                          name: "maintenanceDeptCheckUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.maintenanceDeptCheckUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "maintenanceDeptCheckUserId",
                              $$v
                            )
                          },
                          expression: "supWork.maintenanceDeptCheckUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "maintenanceDeptEntryDeptCd",
                          type: "dept_user",
                          label: "입회자",
                          name: "maintenanceDeptEntryUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.maintenanceDeptEntryUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "maintenanceDeptEntryUserId",
                              $$v
                            )
                          },
                          expression: "supWork.maintenanceDeptEntryUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }